<template>
  <div class="gallery">
    <UrgentBanner
      :type="bannerType"
      :message="bannerMessage"
      v-if="displayBanner"
    />
    <div v-if="photos.length === 0" class="loader__container">
      <div class="loader"></div>
    </div>
    <div class="images__container">
      <img
        v-for="(photo, index) in photos[0]"
        :key="index"
        :src="photo"
        class="image"
      />
    </div>
    <transition appear @enter="enter" @after-enter="afterEnter">
      <div v-if="showTransition" class="transition-div"></div>
    </transition>
  </div>
</template>

<script>
import { auth } from "@/firebase/init";
import db from "@/firebase/init";
import { collection, getDocs } from "firebase/firestore";
import gsap from "gsap";
import UrgentBanner from "@/components/UrgentBanner.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Gallery",
  data() {
    return {
      photos: [],
      showTransition: true,
      displayBanner: false,
      bannerType: "Warning !",
      bannerMessage: "Hello Earthlings",
    };
  },
  components: {
    UrgentBanner,
  },
  methods: {
    async getPhotos() {
      try {
        const querySnapshot = await getDocs(collection(db, "gallery"));
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data().file);
          this.photos.push(doc.data().file);
          console.log("photos array is", this.photos);
        });
      } catch (error) {
        this.bannerType = "Error !!";
        this.bannerMessage =
          "There may have been an internet connection error while loading the images. Please refresh.";
        this.displayBanner = true;
      }
    },
    exitAnimation(done) {
      gsap.to(".transition-div", {
        display: "block",
        height: "100vh",
        duration: 1.9,
        ease: "expo.inOut",
        onComplete: done,
      });
    },
  },
  created() {
    document.title = "Gallery - WCF";
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.$router.push("/signin");
        console.log("Please sign in");
      } else {
        this.getPhotos();
        console.log("User is already signed in. Email is " + user.email);
      }
    });
  },
  setup() {
    const enter = (el, done) => {
      gsap.to(el, {
        height: 0,
        duration: 1.9,
        ease: "expo.inOut",
        onComplete: done,
      });
    };
    const afterEnter = (el) => {
      el.style.display = "none";
    };

    return { enter, afterEnter };
  },
  beforeRouteLeave(to, from, next) {
    this.exitAnimation(next);
  },
};
</script>

<style lang="scss">
.gallery {
  position: relative;
  width: 100%;
  height: 100%;
  .loader__container {
    width: 100%;
    height: fit-content;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .loader {
      width: 50px;
      aspect-ratio: 1;
      border-radius: 50%;
      background: radial-gradient(farthest-side, red 88%, #0000) top/8px 8px
          no-repeat,
        conic-gradient(#0000 30%, red);
      -webkit-mask: radial-gradient(
        farthest-side,
        #0000 calc(100% - 4px),
        #000 0
      );
      animation: l13 1s infinite linear;
    }
    @keyframes l13 {
      100% {
        transform: rotate(1turn);
      }
    }
  }
  .images__container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 0.5rem;
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  .transition-div {
    background-color: red;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 3000;
  }
}

@media only screen and (max-width: 699px) {
  .gallery {
    .images__container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}
</style>
