// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import {
  getAuth,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signOut,
} from "firebase/auth";
import "firebase/functions";
// eslint-disable-next-line no-unused-vars
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDQyEiRoS1KgD-W1uItcqbDRfd81gIdXaE",
  authDomain: "wcfportal-c74fe.firebaseapp.com",
  projectId: "wcfportal-c74fe",
  storageBucket: "wcfportal-c74fe.appspot.com",
  messagingSenderId: "938119013883",
  appId: "1:938119013883:web:a3a3687f4695605f62175e",
  measurementId: "G-SNEK43WRKP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(app);
// Initialize Firebase Authentication and get a reference to the service
// eslint-disable-next-line no-unused-vars
const auth = getAuth(app);
export {
  auth,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  doc,
  setDoc,
  signOut,
};
// init firestore service
const db = getFirestore(app);
export default db;
