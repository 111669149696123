<template>
  <div class="sign-up_container">
    <UrgentBanner
      type="Error !!"
      message="Unable to verify your account. Please retry logging in."
      v-if="showBanner === true"
    />
    <form class="sign-up_form">
      <div class="wcf-details_container">
        <img id="wcf-logo" src="../assets/logo.png" />
        <p class="wcf-text">Winners Campus Fellowship</p>
      </div>
      <div class="form-details">
        <h2 class="form-header">Sign in to your account</h2>
        <div class="form-field">
          <label for="Email">Email</label>
          <input
            name="Email"
            type="text"
            v-model="email"
            placeholder="iamimanma@gmail.com"
            required
          />
        </div>
      </div>
      <div class="submit-details">
        <input type="button" :value="submitLoading" @click="verifyInput" />
        <router-link to="/signup" class="signup">Sign up</router-link>
        <p class="instruction-text">{{ status }}</p>
      </div>
    </form>
  </div>
</template>

<script>
import UrgentBanner from "@/components/UrgentBanner.vue";
import { auth, sendSignInLinkToEmail } from "@/firebase/init";
// eslint-disable-next-line no-unused-vars
import { sendSignInEmail } from "firebase/auth";
// eslint-disable-next-line no-unused-vars
import * as FormData from "form-data";
// eslint-disable-next-line no-unused-vars
import Mailgun from "mailgun.js";

export default {
  name: "SignIn",
  data() {
    return {
      showBanner: false,
      email: "",
      status:
        "A Sign-in Link will be sent to your email address. Click on the link to log into your account.",
      submitLoading: "Receive Sign-in Link",
      verifyURL: process.env.VUE_APP_PATH_START,
      mailgunAPI: "",
    };
  },
  components: {
    UrgentBanner,
  },
  methods: {
    verifyInput() {
      const statusText = document.querySelector(".instruction-text");

      if (this.email == "") {
        this.status = "Please fill in your email";
        statusText.style.color = "red";
      } else {
        this.sendEmailLink();
      }
    },
    async sendEmailLink() {
      const actionCodeSettings = {
        url: process.env.VUE_APP_PATH_START,
        handleCodeInApp: true,
      };

      this.submitLoading = "Sending...";
      const statusText = document.querySelector(".instruction-text");

      try {
        await sendSignInLinkToEmail(auth, this.email, actionCodeSettings);
        window.localStorage.setItem("email", this.email);
        this.status =
          "Signin link has been sent! Please check your email app for the link. Check your inbox, promotion and spam folders.";
        statusText.style.color = "green";
        this.submitLoading = "Link sent! Check your spam inbox";
        console.log("Link sent! Check your spam inbox");
      } catch (error) {
        console.error("Error sending email sign-in link:", error);
        this.submitLoading = "Receive Sign-in Link";
        statusText.style.color = "red";
        if (
          error ==
          "FirebaseError: Firebase: Exceeded daily quota for email sign-in. (auth/quota-exceeded)."
        ) {
          this.status =
            "Try signing in on a different device. Too much sign in requests from this device.";
        } else {
          this.status =
            "Hey, there was an error but no fear, retry signing up again. Could be a simple network issue.";
        }
      }
    },
    /* generateEmailLink() {
      const sendSignInEmail = firebase
        .functions()
        .httpsCallable("sendSignInEmail");
      handleSignIn();

      const handleSignIn = async () => {
        const useremail = this.email;

        try {
          await sendSignInEmail({ email: useremail });
          console.log("Generated Sign-in email sent successfully.");
        } catch (error) {
          console.error("Error sending generated sign-in email:", error);
        }
      };
    }, */
  },
  created() {
    this.verifyURL = process.env.VUE_APP_PATH_START;
    this.mailgunAPI = process.env.VUE_APP_MAILGUN_API_KEY;
    console.log(this.verifyURL);
  },
};
</script>

<style lang="scss" scoped>
:root {
  --font-size-small: 0.8rem;
  --font-size-medium: 0.8rem;
  --black: #000;
  --grey: rgba(0, 0, 0, 0.7);
  --light-grey: rgba(0, 0, 0, 0.5);
  --least-heavy: 400;
  --medium-heavy: 500;
  --heavy: 700;
  --vertical-gap: 2.149vh;
  --horizontal-gap: 1.389vw;
  --accent-colour: #f40200;
}

.sign-up_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 2rem 0px;
  form.sign-up_form {
    width: 51.389vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    max-width: 51.389vw;
    align-items: center;
    .wcf-details_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      img {
        width: 4.34vw;
        height: auto;
      }
      p {
        color: var(--grey);
        font-family: "DM Sans";
        font-size: var(--font-size-small);
        font-style: normal;
        font-weight: var(--least-heavy);
        line-height: normal;
      }
    }
    .form-details {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: var(--vertical-gap);
      padding: var(--vertical-gap) var(--horizontal-gap);
      h2 {
        color: var(--black);
        font-family: "DM Sans";
        font-size: var(--font-size-medium);
        font-style: normal;
        font-weight: var(--medium-heavy);
        line-height: normal;
        letter-spacing: -0.111vw;
        grid-column: 1 / span 2;
        text-align: center;
      }
      .form-field {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
        grid-column: 1 / span 2;
      }
    }
    .submit-details {
      max-width: 27.083vw;
      padding: var(--vertical-gap) var(--horizontal-gap);
      display: flex;
      flex-direction: column;
      gap: var(--vertical-gap);
      align-items: center;
      input {
        border-radius: 0.926vw;
        background: var(--accent-colour);
        color: var(--white);
        font-size: 0.926vw;
        font-family: "DM Sans", Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: var(--heavy);
        line-height: normal;
        letter-spacing: -0.037vw;
        width: fit-content;
        border: none;
        padding: var(--vertical-gap) var(--horizontal-gap);
      }
      p.instruction-text {
        color: var(--light-grey);
        text-align: center;
        font-family: "DM Sans";
        font-size: var(--font-size-tiny);
        font-style: normal;
        font-weight: var(--least-heavy);
        line-height: normal;
      }
      a.signup {
        color: red;
        text-align: center;
        font-family: "DM Sans";
        font-size: 0.926vw;
        font-style: normal;
        font-weight: var(--least-heavy);
        line-height: normal;
        text-decoration: none;
      }
    }
  }
}

@media only screen and (max-width: 699px) {
  .sign-up_container {
    padding: 1rem;
    form.sign-up_form {
      width: 100%;
      max-width: 100%;
      .wcf-details_container {
        img {
          width: 3rem;
        }
      }
      .form-details {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      .submit-details {
        max-width: 100%;
        input,
        a.signup {
          font-size: 0.8rem;
        }
      }
    }
  }
}
</style>
