<template>
  <div class="header">
    <nav class="page-details">
      <img src="../assets/logo2.png" alt="WCF logo" class="wcf-logo" />
      <ul class="page_links">
        <li>
          <router-link to="/profile" :class="{ active: isActive('Profile') }"
            >Profile</router-link
          >
        </li>
        <li>
          <router-link
            to="/materials"
            :class="{ active: isActive('CourseMaterials') }"
            >Course Materials</router-link
          >
        </li>
        <li>
          <router-link to="/gallery" :class="{ active: isActive('Gallery') }"
            >Gallery</router-link
          >
        </li>
        <li>
          <router-link to="/guides" :class="{ active: isActive('Guides') }"
            >Guides</router-link
          >
        </li>
        <li>
          <router-link to="/books" :class="{ active: isActive('Books') }"
            >Books of the Month</router-link
          >
        </li>
      </ul>
      <router-link to="/signin" class="logout" @click="logout"
        >Log out</router-link
      >
    </nav>
  </div>
</template>

<script>
import { auth, signOut } from "@/firebase/init";

export default {
  name: "PageHeader",
  methods: {
    isActive(routeName) {
      return this.$route.name === routeName;
    },
    async logout() {
      await signOut(auth);
      localStorage.removeItem("user");
      this.$router.push("/signin");
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  width: 100vw;
  padding: 1rem;
  display: flex;
  align-items: center;
  background: transparent;
  background-color: #f3f3f3;
  position: fixed;
  z-index: 5;
  position: relative;
  border-bottom: 1px solid rgba(128, 128, 128, 0.25);
  .page-details {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 2.615vw;
      height: 2.315vw;
      border-radius: 50%;
    }
    ul {
      list-style: none;
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    a {
      color: var(--black);
      font-family: "DM Sans";
      font-size: 0.8rem;
      font-style: normal;
      font-weight: var(--medium-heavy);
      line-height: normal;
      letter-spacing: -0.006rem;
      text-decoration: none;
    }
    a.logout {
      color: red;
    }
    a.active {
      font-weight: bold;
      background: red;
      padding: 4px 8px;
      color: white;
      border-radius: 4px;
    }
  }
}

@media only screen and (max-width: 699px) {
  .header {
    overflow-x: auto;
    white-space: nowrap;
    padding: 0;
    .page-details {
      margin: 1rem;
      img {
        display: none;
      }
      ul {
        width: fit-content;
        justify-content: space-between;
      }
      a.logout {
        display: none;
      }
    }
  }
}
</style>
