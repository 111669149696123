<template>
  <Header v-if="!$route.meta.hideNavbar" />
  <router-view v-slot="{ Component }">
    <transition mode="out-in">
      <component :is="Component"></component>
    </transition>
  </router-view>
</template>

<script>
import Header from "@/components/Header.vue";
import gsap from "gsap";

const transitionDiv = document.querySelector(".transitions-div");

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "App",
  components: {
    Header,
  },
  setup() {
    const enter = (done) => {
      gsap.to(transitionDiv, {
        height: 0,
        duration: 1.9,
        ease: "expo.inOut",
        onComplete: done,
      });
    };
    const afterEnter = () => {
      transitionDiv.style.display = "none";
    };
    const leave = (done) => {
      transitionDiv.style.display = "block";
      gsap.to(transitionDiv, {
        height: "100vh",
        duration: 1.9,
        ease: "expo.inOut",
        onComplete: done,
      });
    };

    return { enter, afterEnter, leave };
  },
};
</script>

<style lang="scss">
:root {
  --background-colour: #f3f3f3;
  --font-size-tiny: 0.7rem;
  --font-size-small: 0.8rem;
  --font-size-medium: 1.5rem;
  --black: #000;
  --white: #fff;
  --grey: rgba(0, 0, 0, 0.7);
  --light-grey: rgba(0, 0, 0, 0.5);
  --least-heavy: 400;
  --medium-heavy: 500;
  --heavy: 700;
  --vertical-gap: 1.5rem;
  --horizontal-gap: 1.5rem;
  --accent-colour: #f40200;
}

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  background: var(--background-colour);
  font-family: "DM Sans", Helvetica, sans-serif;
  width: 100dvw;
  height: 100vh;
  overflow-x: hidden;
}

#app {
  width: 100%;
  height: 100%;
}

input,
select {
  border: none;
  width: 100%;
  border-radius: 4px;
  border: 0.5px solid var(--light-grey);
  color: var(--black);
  font-family: "DM Sans";
  font-size: var(--font-size-small);
  font-style: normal;
  font-weight: var(--least-heavy);
  line-height: normal;
  padding: 8px 12px;
  background-color: transparent;
}

input::placeholder {
  color: var(--light-grey);
}

label {
  color: var(--black);
  font-family: "DM Sans";
  font-size: var(--font-size-small);
  font-style: normal;
  font-weight: var(--least-heavy);
  line-height: normal;
  background-color: transparent;
}

li {
  list-style: none;
}

.transitions-div {
  background-color: red;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
}

#wcf-logo,
.wcf-logo {
  border-radius: 50%;
}
</style>
