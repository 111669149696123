<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" />
  </div>
</template>

<script>
import { auth } from "@/firebase/init";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Landing",
  created() {
    auth.onAuthStateChanged(async (user) => {
      if (!user) {
        this.$router.push("/signup");
        console.log("Please sign up");
      } else {
        this.$router.push("/profile");
      }
    });
  },
};
</script>
