<template>
  <div class="guides">
    <UrgentBanner
      :type="bannerType"
      :message="bannerMessage"
      v-if="displayBanner"
    />
    <div v-if="blogs.length === 0" class="loader__container">
      <div class="loader"></div>
    </div>
    <a
      class="blog_container"
      v-for="(blog, index) in blogs"
      :key="index"
      :href="blog.link"
    >
      <img :src="blog.image" alt="" class="blog_image" />
      <p class="blog_title">{{ blog.title }}</p>
    </a>
    <transition appear @enter="enter" @after-enter="afterEnter">
      <div v-if="showTransition" class="transition-div"></div>
    </transition>
  </div>
</template>

<script>
import { auth } from "@/firebase/init";
import db from "@/firebase/init";
import { getDocs, collection, query } from "firebase/firestore";
import gsap from "gsap";
import UrgentBanner from "@/components/UrgentBanner.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Guides",
  data() {
    return {
      blogs: [],
      showTransition: true,
      displayBanner: false,
      bannerType: "Warning !",
      bannerMessage: "Hello Earthlings",
    };
  },
  components: {
    UrgentBanner,
  },
  created() {
    document.title = "Guides - WCF";
    auth.onAuthStateChanged(async (user) => {
      if (!user) {
        this.$router.push("/signin");
        console.log("Please sign in");
      } else {
        console.log("User is already signed in. Email is " + user.email);
        try {
          const blogs = await getDocs(query(collection(db, "blog")));
          blogs.forEach((blog) => {
            console.log(blog.data());
            this.blogs.unshift(blog.data());
            console.log(this.blogs);
          });
        } catch (error) {
          this.bannerType = "Error !!";
          this.bannerMessage =
            "There may have been an internet connection error while fetching the guides. Please refresh.";
          this.displayBanner = true;
        }
      }
    });
  },
  setup() {
    const enter = (el, done) => {
      gsap.to(el, {
        height: 0,
        duration: 1.9,
        ease: "expo.inOut",
        onComplete: done,
      });
    };
    const afterEnter = (el) => {
      el.style.display = "none";
    };

    return { enter, afterEnter };
  },
  methods: {
    exitAnimation(done) {
      gsap.to(".transition-div", {
        display: "block",
        height: "100vh",
        duration: 1.9,
        ease: "expo.inOut",
        onComplete: done,
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    this.exitAnimation(next);
  },
};
</script>

<style lang="scss">
.guides {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  .loader__container {
    width: 100%;
    height: fit-content;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .loader {
      width: 50px;
      aspect-ratio: 1;
      border-radius: 50%;
      background: radial-gradient(farthest-side, red 88%, #0000) top/8px 8px
          no-repeat,
        conic-gradient(#0000 30%, red);
      -webkit-mask: radial-gradient(
        farthest-side,
        #0000 calc(100% - 4px),
        #000 0
      );
      animation: l13 1s infinite linear;
    }
    @keyframes l13 {
      100% {
        transform: rotate(1turn);
      }
    }
  }
  a {
    max-width: 100%;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: white !important;
    text-decoration: none;
    height: fit-content;
    img {
      aspect-ratio: 4/3;
      width: 100%;
      height: auto;
      object-fit: cover;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    p {
      margin: 1rem;
      color: black;
      margin-top: 0;
      font-size: 1rem;
      font-weight: 500;
    }
  }
  .transition-div {
    background-color: red;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 3000;
  }
}

@media only screen and (max-width: 699px) {
  .guides {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
</style>
