/* eslint-disable no-unused-vars */
import { createRouter, createWebHistory } from "vue-router";
import SignUp from "../views/SignUp.vue";
import SignIn from "../views/SignIn.vue";
import VerifySignIn from "../views/VerifySignIn.vue";
import Landing from "../views/Landing.vue";
import DashBoard from "@/views/Dashboard.vue";
import Profile from "@/views/Profile.vue";
import Gallery from "@/views/Gallery.vue";
import Guides from "@/views/Guides.vue";
import CourseMaterials from "@/views/courseMaterials.vue";
import Books from "@/views/booksOfTheMonth.vue";
import { auth } from "@/firebase/init";

const routes = [
  {
    path: "/",
    name: "home",
    component: Landing,
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: SignUp,
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: "/signin",
    name: "signin",
    component: SignIn,
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: "/verify",
    name: "verify",
    component: VerifySignIn,
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: "/dashboard",
    name: "DashBoard",
    component: DashBoard,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: Gallery,
  },
  {
    path: "/guides",
    name: "Guides",
    component: Guides,
  },
  {
    path: "/materials",
    name: "CourseMaterials",
    component: CourseMaterials,
  },
  {
    path: "/books",
    name: "Books",
    component: Books,
  },
];

const router = createRouter({
  // mode: history,
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

/* router.beforeEach(async (to, from) => {
  await auth.onAuthStateChanged(async (user) => {
    if (
      !user &&
      to.name !== "signup" &&
      to.name !== "signin" &&
      to.name !== "verify"
    ) {
      console.log("Please sign up");
      return { name: "Guides" };
    }
  });
}); */

export default router;
