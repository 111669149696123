<template>
  <div class="sign-up_container">
    <UrgentBanner
      type="Success!!"
      message="Unable to verify your account. Please retry logging in."
      v-if="showBanner === true"
    />
    <form class="sign-up_form">
      <div class="wcf-details_container">
        <img id="wcf-logo" src="../assets/logo.png" />
        <p class="wcf-text">Winners Campus Fellowship</p>
      </div>
      <div class="form-details">
        <h2 class="form-header">{{ statusTitle }}</h2>
        <p class="instruction-text">{{ status }}</p>
      </div>
    </form>
  </div>
</template>

<script async>
import UrgentBanner from "@/components/UrgentBanner.vue";
import {
  auth,
  isSignInWithEmailLink,
  signInWithEmailLink,
  doc,
  setDoc,
} from "@/firebase/init";
import db from "@/firebase/init";

export default {
  name: "VerifySignIn",
  data() {
    return {
      showBanner: false,
      statusTitle: "Verifying your account...",
      status:
        "Once your account has been verified, you will be automatically logged in to your dashboard.",
    };
  },
  components: {
    UrgentBanner,
  },
  methods: {
    saveUserDetails() {
      let name = window.localStorage.getItem("name");
      let phoneNumber = window.localStorage.getItem("number");
      let DOB = window.localStorage.getItem("DOB");
      let department = window.localStorage.getItem("department");
      let level = window.localStorage.getItem("level");
      let email = window.localStorage.getItem("email");

      auth.onAuthStateChanged(async (user) => {
        if (user) {
          const userDetail = doc(db, "users", user.uid);
          await setDoc(
            userDetail,
            {
              fullName: name,
              phoneNumber: phoneNumber,
              email: email,
              DOB: DOB,
              department: department,
              level: level,
            },
            { merge: true }
          );
          window.localStorage.removeItem("email");
          window.localStorage.removeItem("name");
          window.localStorage.removeItem("number");
          window.localStorage.removeItem("DOB");
          window.localStorage.removeItem("department");
          window.localStorage.removeItem("level");
          console.log("User details saved in Fire!");
        }
      });
    },
    goToProfile() {
      this.$router.push({ name: "Profile" });
    },
  },
  async mounted() {
    const statusText = document.querySelector(".instruction-text");

    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem("email");
      if (!email) {
        email = window.prompt("Please provide the email for confirmation");
      }

      try {
        await signInWithEmailLink(auth, email, window.location.href);
        if (window.localStorage.getItem("name")) {
          this.saveUserDetails();
        }
        this.statusTitle = "Verified!";
        window.localStorage.removeItem("email");
        window.localStorage.removeItem("name");
        window.localStorage.removeItem("number");
        window.localStorage.removeItem("DOB");
        window.localStorage.removeItem("department");
        window.localStorage.removeItem("level");
        this.$router.push("/profile");
        this.goToProfile();
      } catch (error) {
        this.statusTitle = "Verification failed.";
        this.status =
          "An unexpected error occurred. Please check your internet connection then retry the process again.";
        statusText.style.color = "red";
      }
    } else {
      this.statusTitle = "Verification failed.";
      this.status =
        "Please retry the verification process again. Verify you clicked the signin link sent to your mail.";
      statusText.style.color = "red";
    }
  },
  beforeRouteUpdate(to, next) {
    next;
  },
};
</script>

<style lang="scss" scoped>
:root {
  --font-size-small: 0.926vw;
  --font-size-medium: 2.778vw;
  --black: #000;
  --grey: rgba(0, 0, 0, 0.7);
  --light-grey: rgba(0, 0, 0, 0.5);
  --least-heavy: 400;
  --medium-heavy: 500;
  --heavy: 700;
  --vertical-gap: 2.149vh;
  --horizontal-gap: 1.389vw;
  --accent-colour: #f40200;
}

.sign-up_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  form.sign-up_form {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    max-width: 51.389vw;
    align-items: center;
    .wcf-details_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--vertical-gap);
      img {
        width: 4.34vw;
        height: auto;
      }
      p {
        color: var(--grey);
        font-family: "DM Sans";
        font-size: var(--font-size-small);
        font-style: normal;
        font-weight: var(--least-heavy);
        line-height: normal;
      }
    }
    .form-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 33.796vw;
      gap: var(--vertical-gap);
      padding: var(--vertical-gap) var(--horizontal-gap);
      h2 {
        color: var(--black);
        font-family: "DM Sans";
        font-size: var(--font-size-medium);
        font-style: normal;
        font-weight: var(--medium-heavy);
        line-height: normal;
        letter-spacing: -0.111vw;
        text-align: center;
      }
      p.instruction-text {
        color: var(--black);
        text-align: center;
        font-family: "DM Sans";
        font-size: var(--font-size-small);
        font-style: normal;
        font-weight: var(--least-heavy);
        line-height: normal;
      }
    }
  }
}

@media only screen and (max-width: 699px) {
  .sign-up_container {
    padding: 1rem;
    form.sign-up_form {
      width: 100%;
      max-width: 100%;
      .wcf-details_container {
        img {
          width: 3rem;
        }
      }
      .form-details {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        width: 100%;
      }
      .submit-details {
        max-width: 100%;
        input {
          font-size: 0.8rem;
        }
      }
    }
  }
}
</style>
