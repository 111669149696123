<template>
  <div class="sign-up_container">
    <UrgentBanner
      type="Error !!"
      message="Unable to verify your account. Please retry logging in."
      v-if="showBanner === true"
    />
    <form class="sign-up_form">
      <div class="wcf-details_container">
        <img id="wcf-logo" src="../assets/logo.png" />
        <p class="wcf-text">Winners Campus Fellowship</p>
      </div>
      <div class="form-details">
        <h2 class="form-header">Create your account</h2>
        <div class="form-field">
          <label for="Full name">Full name</label>
          <input
            name="Full name"
            type="text"
            v-model="fullName"
            placeholder="Imanmachukwu Chukwuemeka"
            required
          />
        </div>
        <div class="form-field">
          <label for="Phone number">Phone number</label>
          <input
            name="Phone number"
            type="text"
            v-model="phoneNumber"
            placeholder="08158462275"
            required
          />
        </div>
        <div class="form-field">
          <label for="Email">Email</label>
          <input
            name="Email"
            type="text"
            placeholder="iamimanma@gmail.com"
            v-model="email"
            required
          />
        </div>
        <div class="form-field">
          <label for="Date of birth">Date of birth</label>
          <input
            name="Date of birth"
            type="date"
            v-model="DOB"
            placeholder="4th August"
            required
          />
        </div>
        <div class="form-field">
          <label for="Department">Select department</label>
          <select
            name="Department"
            v-model="department"
            placeholder="Computer Science"
            required
          >
            <option v-if="departments.length == 0" disabled>Loading...</option>
            <option v-for="(department, index) in departments" :key="index">
              {{ department }}
            </option>
          </select>
        </div>
        <div class="form-field">
          <label for="Level">Select level</label>
          <select name="Level" v-model="level" placeholder="300" required>
            <option v-if="levels.length == 0" disabled>Loading...</option>
            <option v-for="(level, index) in levels" :key="index">
              {{ level }}
            </option>
          </select>
        </div>
      </div>
      <div class="submit-details">
        <input type="button" :value="submitLoading" @click="verifyInput" />
        <router-link to="/signin" class="signin">Sign in</router-link>
        <p class="instruction-text">{{ status }}</p>
      </div>
    </form>
  </div>
</template>

<script>
import UrgentBanner from "@/components/UrgentBanner.vue";
import { auth, sendSignInLinkToEmail } from "@/firebase/init";
import db from "@/firebase/init";
// eslint-disable-next-line no-unused-vars
import { doc, setDoc, getDocs, query, collection } from "firebase/firestore";

export default {
  name: "SignUp",
  data() {
    return {
      showBanner: false,
      email: "",
      fullName: "",
      phoneNumber: "",
      DOB: "",
      department: "",
      level: "",
      status:
        "After your account is created, you will be taken to your newly created account. If the department list or level list is not showing, please check your internet connection.",
      submitLoading: "Create account",
      departments: [],
      levels: [],
      verifyURL: process.env.VUE_APP_PATH_START,
    };
  },
  components: {
    UrgentBanner,
  },
  methods: {
    verifyInput() {
      const statusText = document.querySelector(".instruction-text");

      if (
        this.email == "" ||
        this.fullName == "" ||
        this.phoneNumber == "" ||
        this.DOB == "" ||
        this.department == "" ||
        this.level == ""
      ) {
        this.status =
          "Please fill in every field. Your name, email, phone number are used to contact you. Date of Birth is used for our birthday design database. Department and level are used to show you relevant materials and content.";
        statusText.style.color = "red";
      } else {
        this.sendEmailLink();
      }
    },
    async sendEmailLink() {
      const actionCodeSettings = {
        url: process.env.VUE_APP_PATH_START,
        handleCodeInApp: true,
      };

      this.submitLoading = "Sending...";
      const statusText = document.querySelector(".instruction-text");

      try {
        await sendSignInLinkToEmail(auth, this.email, actionCodeSettings);
        window.localStorage.setItem("email", this.email);
        window.localStorage.setItem("name", this.fullName);
        window.localStorage.setItem("number", this.phoneNumber);
        window.localStorage.setItem("DOB", this.DOB);
        window.localStorage.setItem("department", this.department);
        window.localStorage.setItem("level", this.level);
        this.status =
          "Signup link has been sent! Please check your email app for the link. Check your inbox, promotion and spam folders.";
        statusText.style.color = "green";
        this.submitLoading = "Link sent! Check your spam inbox";
        console.log("Link sent");
      } catch (error) {
        console.error("Error sending email sign-in link:", error);
        this.submitLoading = "Create account";
        statusText.style.color = "red";
        if (
          error ==
          "FirebaseError: Firebase: Exceeded daily quota for email sign-in. (auth/quota-exceeded)."
        ) {
          this.status =
            "Try signing up on a different device. Too much sign up requests from this device.";
        } else {
          this.status =
            "Hey, there was an error but no fear, retry signing up again. Could be a simple network issue.";
        }
      }
    },
    saveUserDetails() {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          const userDetail = doc(db, "users", user.uid);
          await setDoc(
            userDetail,
            {
              fullName: this.fullName,
              phoneNumber: this.phoneNumber,
              email: this.email,
              DOB: this.DOB,
              department: this.department,
              level: this.level,
            },
            { merge: true }
          );
          console.log("User details saved in Fire!");
        }
      });
    },
    async getDepartmentAndLevel() {
      const statusText = document.querySelector(".instruction-text");

      const api = this.$prismic.client;
      const response = await api.getSingle("homepage");
      try {
        if (response) {
          this.departments = response.data.departments[0].text
            .split(",")
            .map((item) => item.trim())
            .sort();
          this.levels = response.data.levels[0].text
            .split(",")
            .map((item) => item.trim());
        }
      } catch (error) {
        statusText.style.color = "red";
        this.status =
          "There was an error fetching the departments and levels. Please ensure you have a stable internet connection then retry.";
      }
    },
  },
  async created() {
    //const departments = await getDocs(query(collection(db, "departments")));
    //const levels = await getDocs(query(collection(db, "levels")));
    //console.log(levels._snapshot.docChanges);
    //this.departments = departments._snapshot.docChanges.sort();
    //this.levels = levels._snapshot.docChanges.sort();
    //console.log(this.departments);
    this.getDepartmentAndLevel();
    this.verifyURL = process.env.VUE_APP_PATH_START;
    console.log(this.verifyURL);
  },
};
</script>

<style lang="scss" scoped>
:root {
  --font-size-small: 0.8rem;
  --font-size-medium: 0.8rem;
  --black: #000;
  --grey: rgba(0, 0, 0, 0.7);
  --light-grey: rgba(0, 0, 0, 0.5);
  --least-heavy: 400;
  --medium-heavy: 500;
  --heavy: 700;
  --vertical-gap: 2.149vh;
  --horizontal-gap: 1.389vw;
  --accent-colour: #f40200;
}

.sign-up_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  padding: 2rem 0px;
  form.sign-up_form {
    width: 51.389vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    max-width: 51.389vw;
    align-items: center;
    .wcf-details_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      padding: 1.5rem;
      width: 100%;
      img {
        width: 4.34vw;
        height: auto;
      }
      p {
        color: var(--grey);
        font-family: "DM Sans";
        font-size: var(--font-size-small);
        font-style: normal;
        font-weight: var(--least-heavy);
        line-height: normal;
      }
    }
    .form-details {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: var(--vertical-gap);
      width: 100%;
      h2 {
        color: var(--black);
        font-family: "DM Sans";
        font-size: var(--font-size-medium);
        font-style: normal;
        font-weight: var(--medium-heavy);
        line-height: normal;
        letter-spacing: -0.1vw;
        grid-column: 1 / span 2;
        text-align: center;
      }
      .form-field {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
        width: 100%;
      }
    }
    .submit-details {
      max-width: 27.083vw;
      padding: var(--vertical-gap) var(--horizontal-gap);
      display: flex;
      flex-direction: column;
      gap: var(--vertical-gap);
      align-items: center;
      input {
        border-radius: 0.8rem;
        background: var(--accent-colour);
        color: var(--white);
        font-size: 0.8rem;
        font-family: "DM Sans", Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: var(--heavy);
        line-height: normal;
        letter-spacing: -0.037vw;
        width: fit-content;
        border: none;
        padding: var(--vertical-gap) var(--horizontal-gap);
      }
      p.instruction-text {
        color: var(--light-grey);
        text-align: center;
        font-family: "DM Sans";
        font-size: var(--font-size-tiny);
        font-style: normal;
        font-weight: var(--least-heavy);
        line-height: normal;
      }
      a.signin {
        color: red;
        text-align: center;
        font-family: "DM Sans";
        font-size: 0.8rem;
        font-style: normal;
        font-weight: var(--least-heavy);
        line-height: normal;
        text-decoration: none;
      }
    }
  }
}

@media only screen and (max-width: 699px) {
  .sign-up_container {
    padding: 1rem;
    form.sign-up_form {
      width: 100%;
      max-width: 100%;
      .wcf-details_container {
        img {
          width: 3rem;
        }
      }
      .form-details {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      .submit-details {
        max-width: 100%;
        input,
        a.signin {
          font-size: 0.8rem;
        }
      }
    }
  }
}
</style>
