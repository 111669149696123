<template>
  <div class="details">
    <p class="next-service-text">Next service details</p>
    <div class="service-info">
      <div class="service-title-info">
        <p class="service-type">{{ serviceType }}</p>
        <p class="service-title">{{ serviceTitle }}</p>
      </div>
      <table class="service-meta-info">
        <tr>
          <td>Date:</td>
          <td>{{ date }}</td>
        </tr>
        <tr>
          <td>Time:</td>
          <td>{{ time }}</td>
        </tr>
        <tr>
          <td>Venue:</td>
          <td>{{ venue }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceDetails",
  props: {
    date: String,
    venue: String,
    time: String,
    serviceType: String,
    serviceTitle: String,
  },
};
</script>

<style scoped lang="scss">
.details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.389vw;
  border-radius: 2rem;
  padding: 2.778vw;
  background: #ffe6a6;
  p.next-service-text {
    color: #a77800;
    font-family: "DM Sans";
    font-size: 1.157vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.046vw;
  }
  .service-info {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 2.778vw;
    flex-shrink: 0;
    .service-title-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 1.389vw;
      flex-grow: 1;
      p.service-type {
        color: rgba(0, 0, 0, 0.7);
        font-family: "DM Sans";
        font-size: 1.157vw;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.046vw;
      }
      p.service-title {
        color: #000;
        font-family: "DM Sans";
        font-size: 3.704vw;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.148vw;
      }
    }
    table.service-meta-info {
      td,
      th {
        text-align: left;
        padding: 0px;
      }
      td:nth-child(odd) {
        color: rgba(0, 0, 0, 0.5);
        font-family: "DM Sans";
        font-size: 1.157vw;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.046vw;
      }
      td:nth-child(even) {
        color: #000;
        font-family: "DM Sans";
        font-size: 1.157vw;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.046vw;
      }
    }
  }
}
</style>
