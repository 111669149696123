<!-- eslint-disable no-unused-vars -->
<template>
  <div class="materials">
    <UrgentBanner
      :type="bannerType"
      :message="bannerMessage"
      v-if="displayBanner"
    />
    <div v-if="this.removeLoader === false" class="loader__container">
      <div class="loader"></div>
    </div>
    <p class="material__container_indicator" v-if="books.length > 0">
      Click to download
    </p>
    <div class="material__container">
      <a
        :href="book.book.url"
        class="material"
        v-for="(book, index) in books"
        :key="index"
        target="_blank"
      >
        <div class="material_image"></div>
        <p class="material_name">{{ book.book.name }}</p>
        <p class="material_size">
          {{ (book.book.size / 1024 / 1024).toFixed(1) }}mb
        </p>
      </a>
    </div>
    <div class="material__null" v-if="this.noBook === true">
      <p>No book of the month has been added for this month.</p>
    </div>
    <transition appear @enter="enter" @after-enter="afterEnter">
      <div v-if="showTransition" class="transition-div"></div>
    </transition>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { auth } from "@/firebase/init";
import db from "@/firebase/init";
import {
  getDocs,
  query,
  collection,
  where,
  // eslint-disable-next-line no-unused-vars
  doc,
  // eslint-disable-next-line no-unused-vars
  getDoc,
} from "firebase/firestore";
import gsap from "gsap";
import UrgentBanner from "@/components/UrgentBanner.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Books",
  data() {
    return {
      showTransition: true,
      displayBanner: false,
      bannerType: "Warning !",
      bannerMessage: "Hello Earthlings",
      books: [],
      userDepartment: "",
      userLevel: "",
      noBook: false,
      removeLoader: false,
    };
  },
  components: {
    UrgentBanner,
  },
  created() {
    document.title = "Books - WCF";
    auth.onAuthStateChanged(async (user) => {
      if (!user) {
        this.$router.push("/signin");
        console.log("Please sign in");
      } else {
        console.log("User is already signed in. Email is " + user.email);
        try {
          const userDetails = await getDoc(doc(db, "users", user.uid));
          this.userDepartment =
            userDetails._document.data.value.mapValue.fields.department.stringValue;
          this.userLevel =
            userDetails._document.data.value.mapValue.fields.level.stringValue;
          this.fetchBooksPrismic();
        } catch (error) {
          this.bannerType = "Error !!";
          this.bannerMessage =
            "There may have been an internet error getting user details. Please refresh. If the error persists, pray.";
          this.displayBanner = true;
          this.removeLoader = true;
        }
      }
    });
  },
  methods: {
    async fetchMaterials() {
      try {
        const materials = await getDocs(
          query(collection(db, "materials"), where("title", "==", "MTH111"))
        );
        console.log("Fetching materials");
        if (materials != null) {
          materials.forEach((material) => {
            console.log(material.data().departments);
            console.log("Something cooking");
          });
        } else {
          console.log("Nothing cooking");
        }
      } catch (error) {
        this.bannerType = "Error !!";
        this.bannerMessage =
          "There may have been an internet connection error. Please refresh.";
        this.displayBanner = true;
      }
    },
    async fetchBooksPrismic() {
      const api = this.$prismic.client;
      try {
        const response = await api.getSingle("books_of_the_month");
        //console.log(response);

        if (response.data.books.length > 0) {
          this.removeLoader = true;
          //this.books = response.sort();
          console.log(response.data.books);
          this.books = response.data.books.sort();
          console.log(this.books);
          //console.log(works);
        } else {
          this.noBook = true;
          this.removeLoader = true;
        }
      } catch (error) {
        this.bannerType = "Error !!";
        this.bannerMessage =
          "There may have been an internet error fetching the books. Please refresh. If error persists, pray.";
        this.displayBanner = true;
        this.removeLoader = true;
        console.error(error);
      }
    },
    exitAnimation(done) {
      gsap.to(".transition-div", {
        display: "block",
        height: "100vh",
        duration: 1.9,
        ease: "expo.inOut",
        onComplete: done,
      });
    },
  },
  setup() {
    const enter = (el, done) => {
      gsap.to(el, {
        height: 0,
        duration: 1.9,
        ease: "expo.inOut",
        onComplete: done,
      });
    };
    const afterEnter = (el) => {
      el.style.display = "none";
    };

    return { enter, afterEnter };
  },
  beforeRouteLeave(to, from, next) {
    this.exitAnimation(next);
  },
};
</script>

<style lang="scss">
.materials {
  position: relative;
  height: 100%;
  .loader__container {
    width: 100%;
    height: fit-content;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .loader {
      width: 50px;
      aspect-ratio: 1;
      border-radius: 50%;
      background: radial-gradient(farthest-side, red 88%, #0000) top/8px 8px
          no-repeat,
        conic-gradient(#0000 30%, red);
      -webkit-mask: radial-gradient(
        farthest-side,
        #0000 calc(100% - 4px),
        #000 0
      );
      animation: l13 1s infinite linear;
    }
    @keyframes l13 {
      100% {
        transform: rotate(1turn);
      }
    }
  }
  .material__container_indicator {
    color: black;
    font-size: 0.8rem;
    font-weight: 500;
    opacity: 0.5;
    margin: 1rem 1rem;
    margin-bottom: 0;
    /* margin: 0 auto; */
  }
  .material__container {
    display: grid;
    padding: 1rem;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    a.material {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      padding: 1rem;
      border-radius: 8px;
      background-color: #f0f0f0;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      .material_image {
        width: 8rem;
        height: 12rem;
        background: #8d8d8d;
        border-radius: 8px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      }
      p.material_name {
        margin-top: 0.85rem;
        color: black;
        font-size: 1rem;
        font-weight: 500;
        line-height: normal;
        flex-shrink: 0;
        align-self: flex-start;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      p.material_size {
        margin-top: 0.25rem;
        color: black;
        font-size: 0.8rem;
        font-weight: 500;
        opacity: 0.5;
        align-self: flex-start;
      }
    }
  }
  .material__null {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      color: rgba(0, 0, 0, 0.7);
      text-align: center;
      font-family: "DM Sans";
      font-size: 0.8rem;
      font-style: normal;
      font-weight: var(--least-heavy);
      line-height: normal;
      text-decoration: none;
    }
  }
  .transition-div {
    background-color: red;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 3000;
  }
}

@media only screen and (max-width: 699px) {
  .materials {
    .material__container {
      display: flex;
      flex-direction: column;
      a.material {
        width: 100%;
        height: 17rem;
      }
    }
  }
}
</style>
