<template>
  <div :style="{ backgroundColor: background }" id="banner">
    <p id="banner_type" :style="{ color: textColor }">{{ type }}</p>
    <p id="banner_message">{{ message }}</p>
  </div>
</template>

<script>
export default {
  name: "UrgentBanner",
  props: {
    type: String,
    message: String,
  },
  /*   methods: {
    checkBannerType() {
      const banner = document.getElementById("banner");
      const bannerType = document.getElementById("banner_type");

      if (this.type === "Error !!") {
        banner.style.backgroundColor = "#ff8080";
        bannerType.style.color = "#a40000";
      }
    },
  }, */
  computed: {
    textColor() {
      if (this.type === "Error !!" || this.type === "Danger !!") {
        return "#a40000";
      } else if (this.type === "Urgent Info" || this.type === "Success!!") {
        return "#3c8000";
      } else if (this.type === "Warning !") {
        return "#a4a400";
      } else {
        return "#000";
      }
    },
    background() {
      if (this.type === "Error !!" || this.type === "Danger !!") {
        return "red";
      } else if (this.type === "Urgent Info" || this.type === "Success!!") {
        return "#bbff80";
      } else if (this.type === "Warning !") {
        return "#ffff80";
      } else {
        return "#f3f3f3";
      }
    },
  },
};
</script>

<style scoped lang="scss">
#banner {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  padding: 1rem;
  display: flex;
  gap: 1rem;
  #banner_type {
    /* color: #a4a400; Colour will be set using javascript*/
    text-align: center;
    font-family: "DM Sans";
    font-size: 0.8rem;
    font-style: normal;
    font-weight: var(--heavy);
    line-height: normal;
  }
  #banner_message {
    color: white;
    font-family: "DM Sans";
    font-size: 0.8rem;
    font-style: normal;
    font-weight: var(--medium-heavy);
    line-height: normal;
  }
}
</style>
