<template>
  <div class="profile_container">
    <UrgentBanner
      :type="bannerType"
      :message="bannerMessage"
      v-if="displayBanner"
    />
    <div class="profile">
      <h1>Your Profile</h1>
      <div v-if="Email === ''" class="loader__container">
        <div class="loader"></div>
      </div>
      <main class="profile__details">
        <div class="profile_photo__container">
          <p class="profile_photo__status">{{ profilePhotoStatus }}</p>
          <img :src="profilePhotoURL" v-if="uploaded == true" />
          <p class="profile_photo__uploading" v-if="uploading == true">
            Uploading...
          </p>
          <input
            type="file"
            accept="image/*"
            @change="uploadPhoto"
            v-if="uploaded == false"
          />
        </div>
        <table>
          <tr>
            <td class="table_row_title">Name</td>
            <td class="table_row_content">{{ Name }}</td>
          </tr>
          <tr>
            <td class="table_row_title">Email</td>
            <td class="table_row_content">{{ Email }}</td>
          </tr>
          <tr>
            <td class="table_row_title">DOB</td>
            <td class="table_row_content">{{ DOB }}</td>
          </tr>
          <tr>
            <td class="table_row_title">Phone Number</td>
            <td class="table_row_content">{{ Phone }}</td>
          </tr>
          <tr>
            <td class="table_row_title">Department</td>
            <td class="table_row_content">{{ Department }}</td>
          </tr>
          <tr>
            <td class="table_row_title">Level</td>
            <td class="table_row_content">{{ Level }}</td>
          </tr>
        </table>
      </main>
      <p class="instruction_text">
        {{ statusText }}
      </p>
      <router-link to="/signin" class="logout" @click="logout"
        >Log out</router-link
      >
    </div>
    <transition
      appear
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
      :css="false"
      mode="out-in"
    >
      <div class="transition-div">
        <!-- <p id="welcome_text">{{ welcomeText }}</p> -->
      </div>
    </transition>
  </div>
</template>

<script>
import { auth, signOut } from "@/firebase/init";
import db from "@/firebase/init";
import { doc, getDoc, setDoc } from "firebase/firestore";
import gsap from "gsap";
import UrgentBanner from "@/components/UrgentBanner.vue";
//import SplitType from "split-type";
import {
  getStorage,
  uploadBytesResumable,
  getDownloadURL,
  ref,
} from "firebase/storage";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Profile",
  data() {
    return {
      statusText:
        "If your details are yet to show, it is loading. Edits cannot be made to your profile. To change anything, reach out to the WCFUNN Education and Resources unit.",
      Name: "",
      Phone: "",
      DOB: "",
      Department: "",
      Level: "",
      Email: "",
      displayBanner: false,
      bannerType: "Warning !",
      bannerMessage: "Hello Earthlings",
      uploading: false,
      uploaded: false,
      welcomeText: "",
      profilePhotoStatus: "Add Birthday Photo",
      profilePhotoURL: "",
      uid: "",
    };
  },
  components: {
    UrgentBanner,
  },
  methods: {
    async logout() {
      await signOut(auth);
      localStorage.removeItem("user");
      this.$router.push("/signin");
    },
    exitAnimation(done) {
      gsap.to(".transition-div", {
        display: "block",
        height: "100vh",
        duration: 1.9,
        ease: "expo.inOut",
        onComplete: done,
      });
    },
    uploadPhoto(e) {
      const profileImage = e.target.files[0];
      if (!profileImage) {
        return;
      }
      this.profilePhotoStatus = "Uploading Birthday Photo...";
      const PhotoURL = URL.createObjectURL(profileImage);
      this.uploadPhotoFirebase(PhotoURL, profileImage);
    },
    uploadPhotoFirebase(image, file) {
      const userID = this.uid;

      this.profilePhotoURL = image;

      const storage = getStorage();
      const storageRef = ref(storage, `profile_photos/${userID}/${file.name}`);

      const uploadPhoto = uploadBytesResumable(storageRef, file);

      uploadPhoto.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.error("Error uploading photo", +error);
          this.bannerType = "Error !!";
          this.bannerMessage =
            "There was an issue uploading your profile photo. Please refresh, then retry.";
          this.displayBanner = true;
          this.uploaded = false;
          this.profilePhotoStatus = "Add Birthday Photo";
        },
        () => {
          getDownloadURL(uploadPhoto.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            this.uploaded = true;
            this.profilePhotoStatus = "Birthday Photo";
            this.profilePhotoURL = downloadURL;
            this.uploadDownloadURLFirestore(downloadURL);
          });
        }
      );
    },
    async uploadDownloadURLFirestore(url) {
      const userID = this.uid;

      try {
        await setDoc(
          doc(db, "users", userID),
          {
            profile_photo_url: url,
          },
          {
            merge: true,
          }
        );
      } catch (error) {
        this.bannerType = "Error !!";
        this.bannerMessage =
          "There was an issue uploading your profile photo. Please refresh, then retry.";
        this.displayBanner = true;
        this.uploaded = false;
        this.profilePhotoStatus = "Add Birthday Photo";
      }
    },
    checkFormerURL() {
      const formerURL = document.referrer;
      console.log(formerURL);
      if (formerURL == "https://wcfportal-c74fe.firebaseapp.com/") {
        this.welcomeText = "Welcome";
      }
    },
  },
  mounted() {
    if (this.uploaded == true) {
      this.profilePhotoStatus = "Birthday Photo";
    }
  },
  created() {
    this.checkFormerURL();
    document.title = "Your Profile - WCF";
    auth.onAuthStateChanged(async (user) => {
      if (!user) {
        this.$router.push("/signin");
        console.log("Please sign in");
      } else {
        //console.log("User is already signed in. Email is " + user.email);
        this.uid = user.uid;
        try {
          const userDetails = await getDoc(doc(db, "users", user.uid));
          //console.log(userDetails._document.data.value.mapValue.fields);
          this.DOB =
            userDetails._document.data.value.mapValue.fields.DOB.stringValue;
          this.Name =
            userDetails._document.data.value.mapValue.fields.fullName.stringValue;
          this.Phone =
            userDetails._document.data.value.mapValue.fields.phoneNumber.stringValue;
          this.Department =
            userDetails._document.data.value.mapValue.fields.department.stringValue;
          this.Level =
            userDetails._document.data.value.mapValue.fields.level.stringValue;
          this.Email =
            userDetails._document.data.value.mapValue.fields.email.stringValue;
          this.profilePhotoURL =
            userDetails._document.data.value.mapValue.fields.profile_photo_url.stringValue;
          if (this.profilePhotoURL) {
            this.uploaded = true;
            this.profilePhotoStatus = "Birthday Photo";
          }
        } catch (error) {
          // eslint-disable-next-line no-empty
          if (error.isNaN()) {
          } else {
            console.error("Error:", +error);
            this.bannerType = "Error !!";
            this.bannerMessage =
              "There may have been an internet connection error while getting user details. Please refresh.";
            this.displayBanner = true;
          }
        }
      }
    });
  },
  /* beforeRouteEnter(to, from, next) {
    console.log(from.name);
    //this removes the welcomeeeee text on profile page on page load.
    if (from.name != "verify") {
      next((vm) => {
        vm.welcomeText = "";
      });
    }
  }, */
  setup() {
    const enter = (el, done) => {
      // eslint-disable-next-line no-unused-vars
      //const text = new SplitType("#welcome_text");
      const tl = gsap.timeline();
      tl /* .to(".char", {
        y: 0,
        stagger: 0.02,
        delay: 0.5,
        duration: 0.5,
        ease: "bounce.out",
      }) */.to(el, {
        height: 0,
        duration: 1.9,
        delay: 0.5,
        ease: "expo.inOut",
        onComplete: done,
      });
    };
    const afterEnter = (el) => {
      //const introText = document.getElementsById("welcome_text");
      //introText.style.display = "none";
      el.style.display = "none";
    };
    const leave = (el, done) => {
      el.style.display = "block";
      gsap.to(el, {
        height: "100vh",
        duration: 1.9,
        ease: "expo.inOut",
        onComplete: done,
      });
    };

    return { enter, afterEnter, leave };
  },
  beforeRouteLeave(to, from, next) {
    this.exitAnimation(next);
  },
};
</script>

<style lang="scss">
.profile_container {
  position: relative;
  width: 100%;
  padding: 2rem;
  height: 100%;
  .profile {
    max-width: 51.389vw;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0 auto;
    .loader__container {
      width: 100%;
      height: fit-content;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .loader {
        width: 50px;
        aspect-ratio: 1;
        border-radius: 50%;
        background: radial-gradient(farthest-side, red 88%, #0000) top/8px 8px
            no-repeat,
          conic-gradient(#0000 30%, red);
        -webkit-mask: radial-gradient(
          farthest-side,
          #0000 calc(100% - 4px),
          #000 0
        );
        animation: l13 1s infinite linear;
      }
      @keyframes l13 {
        100% {
          transform: rotate(1turn);
        }
      }
    }
    h1 {
      font-size: 1.75rem;
      font-weight: 500;
      letter-spacing: -0.111vw;
    }
    main {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .profile_photo__container {
        img {
          width: 200px;
          height: 200px;
          object-fit: cover;
          border-radius: 50%;
        }
        p.profile_photo__status {
          font-size: 0.7rem;
          opacity: 0.5;
          font-weight: 400;
          margin-bottom: 0.5rem;
        }
        p.profile_photo__uploading {
          font-size: 0.7rem;
          opacity: 0.5;
          font-weight: 400;
          color: black;
        }
        input {
          border-radius: 0.8rem;
          background: var(--accent-colour);
          color: var(--white);
          font-size: 0.8rem;
          font-family: "DM Sans", Arial, Helvetica, sans-serif;
          font-style: normal;
          font-weight: var(--heavy);
          line-height: normal;
          letter-spacing: -0.037rem;
          width: fit-content;
          border: none;
          padding: 1rem;
        }
      }
      table {
        width: 100%;
        td {
          padding: 0.5rem;
          width: 50%;
          text-align: left;
        }
        td.table_row_title {
          font-weight: 500;
          opacity: 70%;
        }
      }
    }
    p.instruction_text {
      width: 100%;
      font-size: 0.7rem;
      opacity: 0.5;
      font-weight: 400;
      text-align: center;
    }
    a {
      width: 100%;
      color: red;
      font-family: "DM Sans";
      font-size: 0.8rem;
      font-style: normal;
      font-weight: var(--medium-heavy);
      line-height: normal;
      letter-spacing: -0.006rem;
      text-decoration: none;
      text-align: center;
    }
  }
  .transition-div {
    background-color: red;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 3000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    p#welcome_text {
      font-size: 3rem;
      font-weight: 600;
      color: white;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      line-height: 3rem;
      .char {
        transform: translateY(50px);
        transition: transform 0.25s;
      }
    }
  }
}

@media only screen and (max-width: 699px) {
  .profile_container {
    padding: 1rem;
    .profile {
      width: 100%;
      max-width: 100%;
    }
  }
}
</style>
