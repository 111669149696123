<template>
  <div class="announcement">
    <h2 class="heading">{{ heading }}</h2>
    <h4 class="subheading">{{ subheading }}</h4>
    <p class="content">{{ content }}</p>
  </div>
</template>

<script>
export default {
  name: "AnnouncementDetails",
  props: {
    heading: String,
    subheading: String,
    content: String,
  },
};
</script>

<style scoped lang="scss">
.announcement {
  width: 100%;
  height: fit-content;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 2.778vw;
  gap: 1.389vw;
  border-radius: 2rem;
  border: 0.5px solid rgba(0, 0, 0, 0.5);
  h2 {
    color: rgba(0, 0, 0, 0.7);
    font-family: "DM Sans";
    font-size: 1.852vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.074vw;
  }
  h4,
  p {
    color: rgba(0, 0, 0, 0.7);
    font-family: "DM Sans";
    font-size: 1.157vw;
    font-style: normal;
    font-weight: 500;
    line-height: 1.852vw; /* 160% */
    letter-spacing: -0.046vw;
  }
}
</style>
