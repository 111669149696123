<template>
  <div class="home">
    <Header pageName="Dashboard" />
    <div class="container">
      <div class="content_parent">
        <ServiceDetails
          serviceType="Garden of Roses"
          serviceTitle="Love like Wine"
          date="13/08/2021"
          time="10 AM"
          venue="ESA Building"
        />
        <AnnouncementDetails
          :heading="announcementHeading"
          :subheading="announcementSubHeading"
          :content="announcementContent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ServiceDetails from "@/components/Dashboard/ServiceDetails.vue";
import AnnouncementDetails from "@/components/Dashboard/AnnouncementDetails.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "DashBoard",
  data() {
    return {
      courses: ["MTH 121", "COS 101"],
      announcementHeading: "Announcements",
      announcementSubHeading: "Please read carefully",
      announcementContent:
        "Join us this Sunday for a powerful worship experience as we gather together to praise and glorify the name of our Lord. Our guest speaker this week will be sharing an inspiring message centered around hope, faith, and the transformative power of God's love. Don't miss out on our special prayer service this Wednesday evening, where we will come together as a community to lift up our concerns, joys, and praises to the Lord. Calling all families! Our children's ministry will be hosting a fun-filled event after Sunday service, featuring games, crafts, and biblical lessons for kids of all ages. Discover the joy of serving others by volunteering for our upcoming community outreach project. Join us as we spread kindness and compassion to those in need. Mark your calendars for our upcoming baptism service, where individuals will publicly declare their faith and commitment to following Christ. If you're interested in being baptized, please contact the church office for more information. Are you seeking deeper spiritual growth? Join one of our small groups or Bible studies, where you can connect with others and dive into God's Word together.",
    };
  },
  components: {
    ServiceDetails,
    AnnouncementDetails,
  },
};
</script>

<style scoped lang="scss">
.home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .container {
    width: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    .content_parent {
      width: calc(100% - 28.6%);
      width: 100%;
      height: calc(100% - 8.594%);
      padding: 2.778vw;
      display: flex;
      flex-direction: column;
      gap: 2.778vw;
    }
  }
}
</style>
